import { useMsal } from "@azure/msal-react"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { callMsGraphProfilePhoto } from "./graph"

export const fetchUsersToken = createAsyncThunk(
  "users/fetchUsersToken",
  async (request, { dispatch, getState }) => {
    try {
      const { accounts, instance: msalInstance } = useMsal()
      const token = await msalInstance
        .acquireTokenSilent(request)
        .then(data => data.accessToken)
      const tokenforPic = await msalInstance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: accounts[0],
      })
      var photo = await callMsGraphProfilePhoto(tokenforPic.accessToken)
      const userProps = [request.account.name, photo, token]
      return userProps
    } catch (error) {
      return error.response.data
    }
  }
)

const intialStateValue = {
  returned: [],
}
const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    loading: true,
  },
  reducers: {
    sessionExpiry: () => intialStateValue,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersToken.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchUsersToken.fulfilled, (state, action) => {
        state.loading = false
        state.users = action.payload
      })
      .addCase(fetchUsersToken.rejected, (state, action) => {
        state.loading = false
      })
  },
})
export const { sessionExpiry } = usersSlice.actions
export default usersSlice.reducer
