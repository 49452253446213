import React, { useState, useEffect } from 'react';
import { useMsal } from "@azure/msal-react"
import { useCookies } from 'react-cookie';
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { LOG_EVENTS } from "../../graphql-operations"
import { useMutation } from "@apollo/client"

const AttestationModal = ({ onAccept, children }) => {
    const [cookies, setCookie] = useCookies(['attestationAccepted']);
    const { accounts, instance } = useMsal();
    const userName = accounts[0] && accounts[0].username
    const [showModal, setShowModal] = useState(false);
    const [logAttestationInfo, { data, loading, error }] = useMutation(LOG_EVENTS)//Mutation query to log the user.


    useEffect(() => {
        const hasAccepted = cookies.attestationAccepted;
        if (!hasAccepted) {
            // If the cookie doesn't exist or has expired, show the modal
            setShowModal(true);
        }
    }, [cookies.attestationAccepted]);

    const acceptAttestation = () => {
        const input = {
            eventCode: "2",
            eventName: "attestation",
            userDetails: {
                userEmail: userName,
            },
        }
        logAttestationInfo({
            variables: {
                input
            },
        })
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 90 * 24 * 60 * 60 * 1000); // 2 minutes in milliseconds

        // Set a cookie to record the acceptance date
        setCookie('attestationAccepted', new Date().toISOString(), { expires: expirationDate })

        // Hide the modal
        setShowModal(false);

    };

    const rejectAttestation = () => {
        instance.logoutRedirect();
    }

    return (
        <div>
            <div>
                <center>
                    <Modal //no results found Modal
                        open={showModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="model-Text"
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                float: "left",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%,-50%)",
                                maxWidth: 800,
                                minWidth: 800,
                                bgcolor: "background.paper",
                                border: "2px solid #000",
                                overflowY: "auto",
                                p: 1,
                                height: 500,
                                '@media (max-width: 500px)': {
                                    minWidth: 280, // Adjusted minWidth for smaller screens
                                },
                                '@media (max-width: 800px) and (min-width: 500px) ': {
                                    minWidth: 500, // Adjusted minWidth for smaller screens
                                },
                            }}
                        >
                            <center>
                                <Typography fontSize="10" className="attestationText">
                                    <b>Attestation by EY member firm Business Users</b>
                                </Typography>
                            </center>
                            <br />
                            <Typography className="attestationText">
                                As a business user within my EY member firm (hereafter referred to as 'EYMF'), I hereby attest as follows:<br /><br />
                                (i) Any data downloads, APls or data transfers made available from the global system to myself and/or to other business users in my team, which do not contain information defined as EYMF relevant*,
                                will only be used for the purpose of analyzing market trends, historical trends,
                                go-to-market strategy and prospect modeling. The information will be discarded immediately after the stated analysis is complete.<br /><br />

                                (ii) Under no circumstances will any non-EYMF relevant information which may be made available from the global system to myself and/or to other
                                business users in my team be processed and stored in a client center,
                                local database, employee laptop or local server, including any EY managed or external Cloud.<br /><br />

                                (iii) I understand that EY leadership and the EY IT function wilt undertake periodic monitoring to ensure compliance by business users with the requirements of this attestation.
                                EY Global may also undertake inspections for compliance by business users with this attestation and apply production controls on a periodic or as needed basis.<br /><br />

                                (iv) I will duly inform other business users in my team of the requirements of this attestation.<br /><br />
                                Any non-compliance with the provisions of this attestation shall be dealt with in accordance with applicable EY Global data protection and information security policies.<br /><br />

                            </Typography>
                            <center >
                                <Button
                                    onClick={acceptAttestation}
                                    className="primary-btn"
                                    startIcon={<CheckCircleOutlinedIcon className="icon-style" />}
                                    style={{ color: "white" }}
                                >
                                    Accept
                                </Button>
                                &nbsp;
                                <Button
                                    onClick={rejectAttestation}
                                    className="primary-btn"
                                    startIcon={<CancelOutlinedIcon className="icon-style" />}
                                    style={{ color: "white" }}
                                >
                                    Decline
                                </Button>

                            </center>
                            <br />
                            <Typography className="attestationText">
                                *EYMF relevant information is defined as follows:<br /><br />
                                1)Client entities located in EYMF'S local geographic region of which EYMF either has a current engagement or has had an engagement within the previous 10 years. These entities are eligible for unrestricted data feeds, API calls, data transfers, and downloads to EYMF.<br /><br />
                                2)Client entities not located in EYMF'S local geographic region but for which EYMF currently has, or previously had, engagements connected to these entities through an offshore engagement or a parent/child relationship. EYMF will be eligible for limited attributes
                                relating to these entities via data feeds, API calls, data transfers and/or downloads.
                            </Typography>
                        </Box>
                    </Modal>
                </center>
            </div>
            {children}
        </div>
    );
};

export default AttestationModal;


