export const msalConfig = {
  auth: {
    clientId: "",
    authority:
      "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c",
    redirectUri: "https://clientlookup-qa.ey.com", // QA environment
    // redirectUri: "http://localhost:8000", // QA environment
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: "",
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMeProfilePhoto: "https://graph.microsoft.com/v1.0/me/photo/$value",
}
