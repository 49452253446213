import { gql } from "@apollo/client"

export const LOG_EVENTS = gql`
mutation attestaion_log($input: LogEventsInput) {
  logEvents(input: $input){
    data{
      logDate
      logStatus
      }
     Error{
        Message
      }
  }
}`

export const CLIENT_AUTOCOMPLETE = gql`
  query clientAutocomplete($input: AutocompleteClientsInput) {
    autocompleteClients(input: $input) {
      data {
        name
      }
      Error {
        Message
      }
    }
  }
`
export const ENGAGEMENT_AUTOCOMPLETE = gql`
  query engagementAutocomplete($input: AutocompleteEngagementsInput) {
    autocompleteEngagements(input: $input) {
      data {
        name
      }
      Error {
        Message
      }
    }
  }
`

export const FIND_ENGAGEMENT = gql`
  query engagementAutocomplete($input: SearchEngagementsInput) {
    searchEngagements(input: $input) {
      data {
        id
        name
        clientId
        clientName
        costCenterDescription
        createdDate
        engagementStatusName
        engagementTypeName
         companyName
        companyCode
        engagementAllRoles {
          roleDesc
          employeeName
          emailDesc
          employeeCountryNm
          employeeGPNId
        }
        engagementLocation {
          areaName
          countryCode
          countryName
          functionalAreaName
          regionName
        }

        engagementService {
          globalServiceName
          serviceLineName
          subServiceLineName
        }
        engagementSpecificRoles {
          engagementPartnerFirstName
          engagementPartnerMiddleName
          engagementPartnerLastName
          engagementManagerFirstName
          engagementManagerMiddleName
          engagementManagerLastName
          engagementManagerGPNId
          engagementPartnerGPNId
        }
        client {
          data {
            id
            name
            isClientInd
            isAccountInd
            entityType
            entityStatus
            dunsNameDesc
            ultimateDunsDesc
            ultimateDunsNumber
            dunsNumberCode
            gisId
            previousGFISCustomerId
            gisGlobalUltimateDunsId
            altAccountStructureHierarchyInd
            gisGlobalUltimateDunsName
            clientIndustry{
               industrySectorName
               industrySubSectorName
            }
            clientAddress {
              countryName
              countryCode
              addressLine1Desc
              addressLine2Desc
              addressLine3Desc
              addressLine4Desc
              cityName
              postalCode
              stateName
            }
            account {
              accountIndustrySectorName
              accountSegmentName
              accountSegmentSubStatusName
              hqAreaName
              hqMarketSegmentDesc
              hqRegionName
            }
            accountIndustry {
              industrySubSectorName
            }
          }
          Error {
            Message
          }
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`
export const SEARCH_ENGAGAMENTS = gql`
  query engagementAutocomplete($input: SearchEngagementsInput) {
    searchEngagements(input: $input) {
      data {
        id
        name
        clientId
        clientName
        engagementStatusName
        companyCode
        companyName
        client {
          data {
            id
            previousGFISCustomerId
          }
        }
        engagementLocation {
          countryName
          countryCode
        }
        engagementService {
          globalServiceName
        }
        engagementSpecificRoles {
          engagementPartnerEmail
          engagementPartnerFirstName
          engagementPartnerMiddleName
          engagementPartnerLastName
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`

export const DOWNLOADLIST_ENG_LOG = gql`
query engagementAutocomplete($input: SearchEngagementsInput) {
    searchEngagements(input: $input) {
      data {
        id
         }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`


export const SEARCH_ENGAGAMENTS1 = gql`
  query engagementAutocomplete($input: SearchEngagementsInput) {
    searchEngagements(input: $input) {
      data {
        id
        name
        clientId
        clientName
        engagementStatusName
        companyCode
        companyName
        client {
          data {
            id
            previousGFISCustomerId
          }
        }
        engagementLocation {
          countryName
          countryCode
        }
        engagementService {
          globalServiceName
        }
        engagementSpecificRoles {
          engagementPartnerEmail
          engagementPartnerFirstName
          engagementPartnerMiddleName
          engagementPartnerLastName
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`
export const FIND_COUNTRY = gql`
  query {
    getSearchFilters(input: { filterType: "Country" }) {
      data {
        filterType
        code
        name
      }
      Error {
        Message
      }
    }
  }
`

export const FIND_CLIENT_NAME = gql`
  query getclients($input: SearchClientsInput) {
    searchClients(input: $input) {
      data {
        id
        name
        dunsNumberCode
        gisId
        dunsNameDesc
        ultimateDunsDesc
        ultimateDunsNumber
        previousGFISCustomerId
        gisGlobalUltimateDunsName
        entityType
        clientAddress {
          countryName
        }
        clientSpecificRoles {
          globalClientServicePartnerName
          globalClientServicePartnerEmail
          globalClientServicePartnerFirstName
          globalClientServicePartnerMiddleName
          globalClientServicePartnerLastName
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`
export const DOWNLOADLIST_CLIENT_LOG = gql`
   query getclients($input: SearchClientsInput) {
    searchClients(input: $input) {
      data {
        id
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`
export const FIND_CLIENT_NAMES = gql`
  query getclients($input: SearchClientsInput) {
    searchClients(input: $input) {
      data {
        id
        name
        dunsNumberCode
        gisId
        dunsNameDesc
        ultimateDunsDesc
        ultimateDunsNumber
        previousGFISCustomerId
        gisGlobalUltimateDunsName
        entityType
        clientAddress {
          countryName
        }
        clientSpecificRoles {
          globalClientServicePartnerName
          globalClientServicePartnerEmail
          globalClientServicePartnerFirstName
          globalClientServicePartnerMiddleName
          globalClientServicePartnerLastName
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`

export const LAST_UPDATED_RECORDS = gql`
  query {
    getHealth {
      mongodb {
        status {
          client {
            lastUpdated
          }
        }
      }
    }
  }
`

export const FIND_CLIENT_ENGAGEMENT = gql`
  query getclients($input: SearchClientsInput) {
    searchClients(input: $input) {
      data {
        id
        name
        isClientInd
        isAccountInd
        entityType
        entityStatus
        dunsNameDesc
        ultimateDunsDesc
        ultimateDunsNumber
        dunsNumberCode
        gisId
        previousGFISCustomerId
        gisGlobalUltimateDunsId
        altAccountStructureHierarchyInd
        gisGlobalUltimateDunsName

        clientIndustry{
        industrySectorName
        industrySubSectorName
        }

        clientAddress {
          countryName
          countryCode
          addressLine1Desc
          addressLine2Desc
          addressLine3Desc
          addressLine4Desc
          cityName
          postalCode
          stateName
        }
        account {
          accountIndustrySectorName
          accountSegmentName
          accountSegmentSubStatusName
          hqAreaName
          hqMarketSegmentDesc
          hqRegionName
        }
        accountIndustry {
          industrySubSectorName
        }

        engagements {
          data {
            id
            name
            clientId
            clientName
            costCenterDescription
            createdDate
            engagementStatusName
            engagementTypeName
            companyName
            companyCode
            engagementSpecificRoles {
              engagementPartnerFirstName
              engagementPartnerMiddleName
              engagementPartnerLastName
              engagementManagerFirstName
              engagementManagerMiddleName
              engagementManagerLastName
              engagementManagerGPNId
              engagementPartnerGPNId
            }

            engagementLocation {
              areaName
              countryCode
              countryName
              functionalAreaName
              regionName
            }

            engagementService {
              globalServiceName
              serviceLineName
              subServiceLineName
            }
          }

          Error {
            Message
          }
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`
export const GET_ACCOUNT_ROLES = gql`
  query accountRoles($input: GetAccountRolesInput) {
    getAccountRoles(input: $input) {
      data {
        roleCode
        roleDesc
        employeeGUIId
        employeeGPNId
        emailDesc
        employeeRegionName
        employeeAreaName
        employeeFirstName
        employeeMiddleName
        employeeLastName
      }
      Error {
        Message
      }
      totalDocsReturned
    }
  }
`
// this api accepts input as an object(dunsId or clientId as input)
// get client info  for particular client, response is not an array
export const GET_CLIENT = gql`
  query clientAccountRoles($input: GetClientInput) {
    getClient(input: $input) {
      data {
        id
        name
        isClientInd
        isAccountInd
        entityType
        entityStatus
        dunsNameDesc
        ultimateDunsDesc
        ultimateDunsNumber
        dunsNumberCode
        gisId
        previousGFISCustomerId
        gisGlobalUltimateDunsId
        altAccountStructureHierarchyInd
        gisGlobalUltimateDunsName
        channelCode
        channelName
        clientIndustry{
         industrySectorCode
        industrySectorName
        industrySubSectorName
        industrySubSectorCode
        }
        clientAddress {
          countryName
          countryCode
          addressLine1Desc
          addressLine2Desc
          addressLine3Desc
          addressLine4Desc
          cityName
          postalCode
          stateName
        }
        account {       
          accountIndustrySectorName
          accountIndustrySectorCode
          accountSegmentName
          accountSegmentCode
          accountSegmentSubStatusName
          accountSegmentSubStatusCode
          hqAreaName
          hqAreaCode
          hqMarketSegmentDesc
          hqMarketSegmentCode
          hqRegionName
          hqRegionCode
   
        }
        accountIndustry {

          industrySubSectorName
          industrySubSectorCode
        }
      }
      Error {
        Message
      }
    }
  }
`

// api for UDNS Page.
export const GET_CLIENTS_BY_UDUNSID = gql`
  query getClients($input: GetClientsInput) {
    getClients(input: $input) {
      data {
        id
        name
        gisId
        ultimateDunsDesc
        dunsNumberCode
        ultimateDunsNumber
        entityType
        previousGFISCustomerId
        clientSpecificRoles {
          globalClientServicePartnerName
          globalClientServicePartnerFirstName
          globalClientServicePartnerMiddleName
          globalClientServicePartnerLastName
        }
        clientAddress {
          countryName
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`
// api for UDNS Page.
export const GET_CLIENTS_BY_UDUNS_ID = gql`
  query getClients($input: GetClientsInput) {
    getClients(input: $input) {
      data {
        id
        name
        gisId
        ultimateDunsDesc
        dunsNumberCode
        ultimateDunsNumber
        entityType
        previousGFISCustomerId
        clientSpecificRoles {
          globalClientServicePartnerName
          globalClientServicePartnerFirstName
          globalClientServicePartnerMiddleName
          globalClientServicePartnerLastName
        }
        clientAddress {
          countryName
        }
      }
      totalDocsReturned
      Error {
        Message
      }
    }
  }
`

// get single engagement by engagementId
export const GET_ENGAGEMENT = gql`
  query getEngagement($input: GetEngagementInput) {
    getEngagement(input: $input) {
      data {
        id
        name
        engagementStatusName
        engagementStatusCode
        createdDate
        clientId
        clientName
        engagementTypeName
        engagementTypeCode
        costCenterDescription
        costCenterId
        companyName
        companyCode
        projectId
        projectName
        engagementActivities{
                engagementActivityId
                engagementActivityName
                isActiveInd
            }
        engagementLocation {
          areaName
          areaCode
          regionName
          regionCode
          countryCode
          countryName
          functionalAreaName
          functionalAreaCode

        }
        engagementService {
          globalServiceName
          globalServiceCode
          serviceLineName
          serviceLineCode
          subServiceLineName
          subServiceLineCode
        }

        engagementAllRoles {
          roleDesc
          employeeName
          emailDesc
          employeeCountryNm
          employeeGPNId
          employeeGUIId
          employeeFirstName
          employeeLastName
          employeeMiddleName
        }
        engagementSpecificRoles {
          engagementPartnerEmail
          engagementManagerEmail
          engagementPartnerFirstName
          engagementPartnerLastName
          engagementPartnerMiddleName
          engagementManagerFirstName
          engagementManagerMiddleName
          engagementManagerLastName
        }
        client {
          data {
            name
          }
          Error {
            Message
          }
        }
        opportunity{
                data{
                    name
                    id
                }
                Error{
                    Message
                    }
            }
      }
      Error {
        Message
      }
    }
  }
`

// get input as clientId, duns, clientName and returns multiple engagements
export const GET_ENGAGEMENTS_FOR_CLIENT = gql`
  query getEngagements($input: GetEngagementsInput) {
    getEngagements(input: $input) {
      data {
        id
        name
        clientId
        engagementStatusName
        createdDate
        clientName
        companyCode
        companyName
        engagementLocation {
          countryName
          countryCode
        }
        engagementService {
          globalServiceName
          globalServiceCode
        }
        engagementSpecificRoles {
          engagementPartnerFirstName
          engagementPartnerLastName
          engagementPartnerMiddleName
          engagementPartnerEmail
          engagementPartnerGPNId
          engagementManagerFirstName
          engagementManagerLastName
          engagementManagerMiddleName
        }
      }
      Error {
        Message
      }
    }
  }
`

// get input as clientId, duns, clientName and returns multiple engagements
export const GET_ENGAGEMENTS_FOR_CLIENTID = gql`
  query getEngagements(
    $input1: GetEngagementsInput
    $input2: GetEngagementsInput
  ) {
    mdm: getEngagements(input: $input1) {
      data {
        id
        name
        clientId
        engagementStatusName
        createdDate
        clientName
        companyCode
        companyName
        engagementLocation {
          countryName
          countryCode
        }
        engagementService {
          globalServiceName
          globalServiceCode
        }
        engagementSpecificRoles {
          engagementPartnerFirstName
          engagementPartnerMiddleName
          engagementPartnerLastName
          engagementManagerName
          engagementManagerFirstName
          engagementManagerLastName
          engagementManagerMiddleName
          engagementPartnerEmail
          engagementPartnerGPNId
        }
      }
      Error {
        Message
      }
    }
    gfis: getEngagements(input: $input2) {
      data {
        id
        name
        clientId
        engagementStatusName
        createdDate
        clientName
        companyCode
        companyName
        engagementLocation {
          countryName
          countryCode
        }
        engagementService {
          globalServiceName
          globalServiceCode
        }
        engagementSpecificRoles {
          engagementPartnerFirstName
          engagementPartnerMiddleName
          engagementPartnerLastName
          engagementManagerName
          engagementManagerFirstName
          engagementManagerLastName
          engagementManagerMiddleName
          engagementPartnerEmail
          engagementPartnerGPNId
        }
      }
      Error {
        Message
      }
    }
  }
`
