import { graphConfig } from "./authConfig"

export async function callMsGraphProfilePhoto(accessToken) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append("Authorization", bearer)
  headers.append("Content-Type", "image/jpeg")

  const options = { method: "GET", headers: headers }

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  const response = await fetch(graphConfig.graphMeProfilePhoto, options)
  try {
    if (response != null && response.ok) {
      const blob = await response.blob()
     
      if (blob !== null) {
        const blob64 = await blobToBase64(blob)
        return blob64
      } else {
        throw new Error("Profile image not found")
      }
    }
  } catch (error) {
    throw new Error("Profile image not found")
  }
}
