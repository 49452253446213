import React from "react"
import { useEffect, useState } from "react"
import { loginRequest, msalConfig } from "./authConfig"
import { fetchData } from "./utils/helper"
import { InteractionType, PublicClientApplication } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { CustomNavigationClient } from "./utils/NavigationClient"
import Idle from "./utils/Idle"
import AttestationModal from "./components/Attestation"

export default function AuthWrapper({ children }) {
  const [config, setConfig] = useState(msalConfig)
  const [data, setData] = useState()
  const [instance, setInstance] = useState()
  const [loginScope, setLoginScope] = useState(loginRequest)

  useEffect(() => {
    fetchData().then(data => {
      config.auth.clientId = data.clientId
      loginScope.scopes = [data.loginScope]
      const publicClientInstance = new PublicClientApplication(config)
      const navigationClient = new CustomNavigationClient()
      publicClientInstance.setNavigationClient(navigationClient)
      setInstance(publicClientInstance)
      setData(data)
    })
  }, [])

  return (
    <>
      {data && (
        <MsalProvider instance={instance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <AttestationModal><Idle>{children}</Idle></AttestationModal>
          </MsalAuthenticationTemplate>
        </MsalProvider>
      )}
    </>
  )
}
