import * as React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { Button } from "@mui/material"
import "../../pages/style.css"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  minHeight: 200,
  maxWidth: 500,
  maxHeight: 290,
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  p: 4,
}

const buttonStyle = {
  color: "#fff",
}

export default function AlertModal(props) {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => setOpen(false)

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="alert-box"
      >
        <Box sx={style}>
          {props.remaining ? (
            <>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="alert-modal-main"
              >
                Your session is about to time out in
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="alert-modal-main"
              >
                {props.remaining} seconds
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="alert-modal-sub"
              >
                Do you want to stay logged in?
              </Typography>
              <br />
              <br />
              <div className="columns">
                <div className="avatar">
                  <Button
                    className="primary-btn"
                    style={buttonStyle}
                    onClick={() => {
                      props.handleStillHere()
                    }}
                  >
                    Stay logged in
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="alert-modal-main"
            >
              Logging Out...
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  )
}
