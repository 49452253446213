import * as React from "react"
import { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useMsal } from "@azure/msal-react"
import AlertModal from "../components/AlertModal"

const timeout = 1860_000
const promptBeforeIdle = 60_000

export default function Idle({ children }) {
  const { instance } = useMsal()
  const [state, setState] = useState("Active")
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)

  const onIdle = () => {
    setState("Idle")
    pause()
  }

  const onActive = () => {
    setState("Active")
    setOpen(false)
  }

  const onPrompt = () => {
    setState("Prompted")
    setOpen(true)
  }

  const events = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
  ]

  const { getRemainingTime, activate, pause } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    events,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
      // console.log(
      //   "(Math.ceil(getRemainingTime() / 1000)",
      //   Math.ceil(getRemainingTime() / 1000)
      // )
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  React.useEffect(() => {
    if (remaining == 0) {
      instance.logoutRedirect()
    }
  }, [remaining])

  return (
    <>
      {children}
      <div
        className="modal"
        style={{
          display: open ? "flex" : "none",
        }}
      >
        <AlertModal
          open={open}
          handleStillHere={handleStillHere}
          remaining={remaining}
        />
      </div>
    </>
  )
}
